<template>
  <div :style="format" class="kartuSoalPdf">
    <div class="d-flex align-items-center mx-5 mt-3">
      <div>
        <img
          :src="institusi ? institusi.logo : '/Logo-Hasan.png'"
          width="100px"
          alt
        />
      </div>
      <div class="text-center ml-4">
        <h3>{{ institusi ? institusi.nama.toUpperCase() : "-" }}</h3>
        <p>{{ institusi.alamat ? institusi.alamat.lokasi : "-" }}</p>
        <p>E-Mail : {{ institusi ? institusi.email : "-" }}</p>
      </div>
    </div>
    <div class="mx-5">
      <hr style="border: 1px solid black" />
      <h3 class="text-center" style="text-transsform: uppercase;">DINAS PENDIDIKAN PROVINSI DKI JAKARTA</h3>
      <h3 class="text-center" style="text-transsform: uppercase;">{{ detailCbt.tipePanjang }} ({{ detailCbt.tipe }}) SEMESTER {{ semester }} </h3>
      <h3 class="text-center">{{ institusi ? institusi.nama.toUpperCase() : "-" }}</h3>
      <h3 class="text-center">KARTU SOAL</h3>
      <table border="0" class="mt-3">
        <tr>
          <td>Jenis Sekolah</td>
          <td>:&nbsp;</td>
          <td width="35%">SMP</td>
          <td>Nama Penyusun</td>
          <td>:&nbsp;</td>
          <td>{{ detailCbt.cbt_history.createdBy }}</td>
        </tr>
        <tr>
          <td>Kelas</td>
          <td>:&nbsp;</td>
          <td>{{ kelas }}</td>
          <td>Asal Sekolah</td>
          <td>:&nbsp;</td>
          <td>{{ institusi ? institusi.nama : "-" }}</td>
        </tr>
      </table>
      <hr style="border: 1px solid black" />
      <table border="1">
        <tr>
          <td width="35%">
            <span class="font-weight-bold">STANDAR KOMPETENSI LULUSAN</span>
          </td>
          <td width="35%">
            <span class="font-weight-bold">INDIKATOR</span>
          </td>
          <td>
            <span class="font-weight-bold">NO SOAL</span>
          </td>
          <td>
            <span class="font-weight-bold">KUNCI JAWABAN OBYEKTIF</span>
          </td>
        </tr>
        <tr>
          <td>{{ item.standar_kompetensi }}</td>
          <td>{{ item.indikator }}</td>
          <td>{{ Number(no) }}</td>
          <td>{{ item.kunci_jawaban }}</td>
        </tr>
        <tr>
          <td colspan="4">&nbsp;</td>
        </tr>
        <tr>
          <td colspan="2">
            <span class="font-weight-bold">BUTIR SOAL</span>
          </td>
          <td colspan="2">
            <span class="font-weight-bold">JAWABAN</span>
          </td>
        </tr>
        <tr>
          <td class="butirSoal" colspan="2">
            <span
              style="font-family: 'amiri' !important"
              ref="soal"
              v-html="item.soal"
            ></span>
          </td>
          <td colspan="2">
            <!-- {{item.jawaban}} -->
            <div
              style="font-family: 'amiri' !important"
              class="mt-1 d-flex"
              v-for="(itemJawaban, j) in item.jawaban"
              :key="j"
            >
              <div>{{String.fromCharCode(65 + j)}}.&nbsp;</div>
              <span class="butirSoal soal" ref="jawaban" v-html="itemJawaban"></span>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { renderMathMl } from '@/helpers/mathjax'
export default {
  data() {
    return {}
  },
  props: ['detailCbt', 'item', 'no', 'format', 'kelas'],
  computed: {
    institusi() {
      return this.$store.state.master.institusi
    },
    semester() {
      return this.detailCbt.tahun_ajaran?.semester.toUpperCase()
    },
  },
  mounted() {
    let arrayOfRef = []
    const soal = this.$refs.soal
    const jawaban = this.$refs.jawaban
    if (soal || jawaban) {
      if (soal) {
        if (Array.isArray(soal)) arrayOfRef = arrayOfRef.concat(soal)
        else arrayOfRef.push(soal)
      }

      if (jawaban) {
        if (Array.isArray(jawaban)) arrayOfRef = arrayOfRef.concat(jawaban)
        else arrayOfRef.push(jawaban)
      }

      renderMathMl(arrayOfRef)
    }
  },
}
</script>
